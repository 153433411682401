@import './colors.scss';

@keyframes fadeInAnimation{
  0%{ opacity: 0; }
  100%{ opacity: 1; }
}

.modal-dialog {
  max-width: 660px;
}

.modal-header {
  display: block;
  padding: 0;
  border-top: 1px solid $primary-orange;
  border-bottom: 0;
  border-radius: 0px;
}

.modal-body {
  padding: 0;
}

.modal-delete-file {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 120vh;
  background-color: rgba(50, 50, 50, 0.3);
  overflow: hidden;

  .dialog-box {
    width: 65%;
    margin: 100px auto;
    background-color: $white;
    border: solid 16px rgba(220, 220, 220, 0.85);

    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    position: relative;
  }

  .attention-icon {
    color: $primary-petrol-blue;
    font-size: 2em;
  }

  .close {
    color: $primary-petrol-blue;
    opacity: 1;
  }

  .btn {
    border-radius: 0px;
  }

  .btn-continue {
    background-color: $primary-petrol-blue;
    color: $white;
  }

  .btn-cancel {
    border: 2px solid $primary-petrol-blue;
    color: $primary-petrol-blue;
  }
}
