$white: #ffffff;
$black: #000000;
$black-2: #323232;

$grey-1: #f5f5f5;
$grey-2: #EBEBEB;
$grey-3: #DCDCDC;
$grey-4: #B9B9B9;
$grey-5: #969696;
$grey-6: #737373;
$grey-7: #505050;
$grey-8: #2E2E2E;
$text-grey: #323232;

$msg-warning: #C83727;

$primary-petrol-blue: #007398;
$primary-orange: #e9711c;
$primary-lt-blue: #3679E0;

$secondary-orange: #FFF0E4;
$secondary-pale-blue: #CDE4FF;
